@import '../../../styles/controls';

.feildlabel {
  flex: 1 1 auto;
  margin-bottom: 0.12rem;
  @extend %flexbox;
  @extend %item-center;
  .labelBlock {
    font-size: 14px;
    font-weight: 400;
    color: theme-colors("grays", "gray-8");
    &:first-letter {
      text-transform: capitalize;
    }
  }
  .starIcon {
    width: 0.469rem;
    height: 0.469rem;
    font-size: 1.2rem;
    margin-left: 3px;
    @extend %flexbox;
    @extend %all-center;
    svg {
      width: 100%;
      height: 100%;
      path {
        fill: theme-colors("functional", "error");
      }
    }
  }
}


span.and-or-marker{
  flex: 0 0 auto;
  color: theme-colors('primary', 'base');
  font-weight: 600;
  text-transform: uppercase;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.filterWrapper {
  row-gap: 1rem;
  padding: 1rem;
  @extend %flex-column;
  @extend %radius-lg;
  .filterblockInner {
    flex: 1 1 auto;
    row-gap: 1rem;
    @extend %flex-column;
    .filterContention {
      flex: 0 1 auto;
      @extend %flexbox;
      @extend %item-center;
      span {
        flex: 0 0 auto;
        color: theme-colors('primary', 'base');
        font-weight: 600;
        text-transform: uppercase;
        &:not(:last-child) {
          margin-right: 0.5rem;
        }
      }
    }
    .advanceBlock {
      overflow: auto;
      display: grid;
      row-gap: 0.5rem;
      column-gap: 0.5rem;
      padding: 0.5rem;
      grid-template-columns: 1fr 140px;
      grid-template-rows: 1fr;
      background-color: theme-colors('grays', 'gray-2', 0.1);
      @extend %radius-lg;
      &__element {
        padding: 0.5rem;
        flex: 1 1 auto;
        row-gap: 1rem;
        background-color: theme-colors('grays', 'gray-2', 0.1);
        @extend %radius-lg;
        @extend %flex-column;
        .advanceSwitch {
          flex: 0 1 auto;
          column-gap: 0.5rem;
          @extend %flexbox;
          @extend %item-center;
          span {
            flex: 0 0 auto;
            font-weight: 600;
            color: theme-colors('primary', 'base');
            text-transform: uppercase;
            @extend %flexbox;
            @extend %all-center;
            &:nth-child(2) {
              max-width: 30px;
              min-width: 30px;
              overflow: hidden;
            }
          }
        }
        .advanceFilter {
          display: grid;
          row-gap: 0.5rem;
          column-gap: 0.5rem;
          grid-template-columns: 1fr 126px;
          grid-template-rows: 1fr;
          &__facetgroup {
            flex: 1 1 auto;
            padding: 0.5rem;
            background-color: theme-colors('grays', 'gray-0');
            @extend %flex-column;
            @extend %radius-lg;
            .advanceFacet {
              flex: 1 1 auto;
              .facetBlock {
                flex: 1 1 auto;
                row-gap: 1rem;
                @extend %flex-column;
                li {
                  flex: 1 1 auto;
                  @extend %flex-column;
                  .facetswitch {
                    flex: 1 1 auto;
                    padding: 1rem 0;
                    @extend %flexbox;
                    @extend %item-center;
                    span {
                      flex: 0 0 auto;
                      font-weight: 600;
                      color: theme-colors('primary', 'base');
                      text-transform: uppercase;
                      &:not(:last-child) {
                        margin-right: 0.5rem;
                      }
                    }
                  }
                  .facetElement {
                    @extend %flex-column;
                    &__item {
                      flex: 1 1 auto;
                      row-gap: 0.75rem;
                      @extend %flex-column;
                      .facetitemPane {
                        flex: 1 1 auto;
                        @extend %flex-column;
                        .facetSelect,
                        .facetFilter {
                          flex: 1 1 auto;
                          min-width: 100px;
                          .advanceDateblock {
                            @extend %flex-column;
                            label {
                              flex: 1 1 auto;
                              margin-bottom: 0;
                              font-size: 14px;
                              font-weight: 400;
                              color: theme-colors('grays', 'gray-8');
                            }
                          }
                        }
                      }
                    }
                    &__buttons {
                      flex: 0 0 auto;
                      column-gap: 0.5rem;
                      @extend %flexbox;
                      @extend %item-end;
                      @extend %justify-end;
                      .facetControls {
                        flex: 0 0 auto;
                      }
                    }
                  }
                }
              }
            }
          }
          &__facetadd {
            flex: 1 1 auto;
            column-gap: 0.5rem;
            @extend %flexbox;
            @extend %item-center;
            @extend %justify-end;
            .facetaddButton {
              flex: 0 0 auto;
            }
          }
        }
      }
      &__addgroup {
        flex: 0 0 auto;
        column-gap: 0.5rem;
        @extend %flexbox;
        @extend %item-center;
        @extend %justify-end;
        .addgroupElement {
          flex: 1 1;
        }
      }
    }
  }
}
.facetButtons {
  padding: 0.25rem 0.5rem;
  min-width: auto !important;
  text-transform: capitalize;
}
