@import '../../styles/controls';

.filterButton svg{
    width: 100%;
    height: 100%;
    
    fill: theme-colors('grays', 'gray-0');
    @extend %isIn;

    &:hover{
    
        fill: theme-colors('primary', 'dark');
        @extend %isIn;
        
    }
}

  .filterIsOn{
    transform: (rotate(180deg));
  }

  .filterButton{
    background-color: #3c32f2;
    color: white;

    &:focus  {
        background-color: #3c32f2;
        color: white;
        
    }
    
    &:hover  {
      background-color: theme-colors('secondary', 'base');
      border-color: theme-colors('secondary', 'base');
      color: theme-colors('primary', 'dark');
      @extend %isIn;

      svg {
        width: 100%;
        fill: theme-colors('primary', 'dark');
      }
    }
  }