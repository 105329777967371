.long-input{
    max-width: 320px;
    @media (min-width: 1300px) {
        max-width: 400px;
    }
    @media (min-width: 1600px) {
        max-width: 600px;
    }
    @media (min-width: 1700px) {
        max-width: 65%;
    }
}