@import '../../styles/controls';
.ant-tabs {
  flex: 1 1 auto;
  @extend %flex-column;
  .ant-tabs-nav {
    padding: 0 $tab-padding-md;
    margin: 0;
    height: $tab-height-md;
    @extend %flexbox;
    @extend %item-center;
    @extend %justify-between;
    @include box-shadow(0 0 0 transparent);
    &::before {
      border-bottom: $line;
    }
    .ant-tabs-nav-wrap {
      flex: 1 0 auto;
      .ant-tabs-nav-list {
        @extend %flexbox;
        @extend %item-center;
        .ant-tabs-tab {
          .ant-tabs-tab-btn {
            color: theme-colors('grays', 'gray-8');
          }
          &.ant-tabs-tab-active {
            flex: 0 0 auto;
            .ant-tabs-tab-btn {
              color: theme-colors('grays', 'gray-8');
            }
          }
        }
        .ant-tabs-ink-bar {
          background-color: theme-colors('primary', 'base');
        }
      }
    }
  }
  .ant-tabs-content-holder {
    @extend %flex-column;
    .ant-tabs-content {
      flex: 1 1 auto;
      max-width: 100%;
      @extend %flex-column;
      .ant-tabs-tabpane {
        flex: 1 1 auto;
        &.ant-tabs-tabpane-active {
          @extend %isIn;
          @extend %flex-column;
        }
      }
    }
  }
  &.search-tab {
    margin: 0;
    .ant-tabs-nav {
      background-color: transparent;
    }
  }
  &.insight-tab-block {
    margin: 0;
    .ant-tabs-nav {
      background-color: transparent;
    }
    .ant-tabs-content {
      padding: $content-padding-md;
    }
  }
}
