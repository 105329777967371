@import "../../../styles/controls";
.filtertagsWrapper {
  column-gap: 0.5rem;
  padding: 0 1.5rem;
  @extend %flexbox;
  @extend %item-start;
  @extend %justify-between;
  .filterTags {
    max-width: 100%;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
    @extend %flexbox;
    @extend %item-center;
    @extend %item-wrap;
    li {
      flex: 0 0 auto;
      max-width: 100%;
      .outerTags {
        padding: 0.25rem 0.5rem;
        column-gap: 0.5rem;
        row-gap: 0.5rem;
        margin: 0;
        max-width: 100%;
        border-color: theme-colors("primary", "base", 0.3);
        background-color: theme-colors("grays", "gray-0");
        @extend %flexbox;
        @extend %item-center;
        @extend %item-wrap;
        @extend %radius-md;
        .tagInner {
          flex: 0 0 auto;
          padding: 0 0.5rem;
          column-gap: 0.5rem;
          border-color: theme-colors("primary", "base", 0.2);
          background-color: theme-colors("grays", "gray-1");
          margin: 0;
          @extend %radius-md;
          @extend %flexbox;
          @extend %item-center;
        }
        .tagLabel {
          flex: 0 0 auto;
        }
      }
    }
  }
  .clearFilter {
    flex: 0 0 auto;
    padding: 0.25rem 1rem;
    background-color: theme-colors("primary", "base");
    color: theme-colors("grays", "gray-1");
    @extend %radius-md;
  }
}
