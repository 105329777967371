@import "../../styles/controls";
.datesBlock {
  @extend %flex-column;
  &__list {
    flex: 1 1 auto;
    @extend %flex-column;
    li {
      flex: 1 1 auto;
      @extend %flex-column;
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
      .datesHeader {
        flex: 0 1 auto;
        span {
          font-size: 14px;
          color: theme-colors("grays", "gray-9");
        }
      }
      .dateChoose {
        flex: 1 1 auto;
        margin-top: 0.5rem;
        padding: 0.5rem;
        border: 2px solid green;
        //background-color: theme-colors("primary", "light", 0.5);
        @extend %flexbox;
        @extend %item-center;
        @extend %isOut;
        &__items {
          flex: 1 1 auto;
          @extend %flex-column;
          &:not(:last-child) {
            margin-right: 10px;
          }
          label {
            margin-bottom: 3px;
            font-size: 13px;
            color: theme-colors("primary", "dark");
          }
        }
      }
      .dateWarning {
        color: theme-colors("functional", "error");
      }
    }
  }
}
