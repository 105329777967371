@import '../../styles/controls';
.simplebar-block {
  flex: 1 1 auto;
  @extend %flex-column;
  .simplebar-wrapper {
    flex: 1 1 auto;
    margin: 0;
    padding: 0;
    @extend %flex-column;
    .simplebar-mask {
      flex: 1 1 auto;
      @extend %flex-column;
      .simplebar-offset {
        @extend %flex-column;
        .simplebar-content-wrapper {
          @extend %flex-column;
          .simplebar-content {
            padding: 0;
            flex: 0 1 auto;
            @extend %flex-column;
          }
        }
      }
    }
  }
  .simplebar-track {
    background-color: transparent;
    z-index: 0;
    @extend %isIn;
    @include border-radius(50px);
    .simplebar-scrollbar {
      background-color: #d6e0e9;
      @include border-radius(50px);
      @include transform(translate(0, 0));
    }
    &.simplebar-vertical {
      height: 100%;
      width: 12px;
    }
    &.simplebar-horizontal {
      width: 100%;
      height: 2px;
      max-width: fit-content;
    }
  }

  .body-scroller {
    max-height: 100vh;
  }
  .filter-scroller {
    padding-right: 0.75rem;
    max-height: calc(100vh - 190px);
  }
  .field-scroller {
    max-height: calc(100vh - 470px);
  }
  .inner-scroller {
    max-height: 300px;
  }
  .addFilter-scroller {
    max-height: 200px;
  }
  .setting-scroller {
    max-height: calc(100vh - 460px);
  }
  .chart-scroller {
    max-height: calc(100vh - 270px);
  }
  .preview-scroller {
    max-height: calc(100vh - 200px);
  }
  .checklist-scroller {
    max-height: 140px;
  }
  .saved-search-scroll {
    max-height: calc(100vh - 300px);
  }
  .advance-search-scroller {
    max-height: calc(100vh - 220px);
  }
  .result-view-scroller {
    max-height: calc(100vh - 320px);
  }
  .recentSearchModal {
    max-height: calc(200px);
  }
  .insight-drawer-scroller {
    flex: 1 1 auto;
    max-height: calc(100vh - 207px);
  }
  .insight-graph-scroller {
    flex: 1 1 auto;
    max-height: calc(100vh - 170px);
  }
  .add-tags-scroller {
    max-height: calc(100vh - 200px);
  }
}
