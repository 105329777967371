.grid-item{
    flex: 1 1 auto;
    overflow: hidden;
    background-color: wheat;
    min-height: 650px;
    border: 2px solid  green;

}

.layout{
    border: 2px solid red;
    height: 600px;
    display: flex;
}