@import '../../styles/controls';

.mainWrapper {
  @extend %flex-column;

  .searchHolder {
    position: fixed;
    height: 100vh;
    right: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    max-width: 80%;
    @media(min-width: 1200px){
      min-width: 850px;
    }
    overflow: scroll;
    z-index: 999;
    background-color: theme-colors('grays', 'gray-0');
    @include box-shadow(-2px 0 8px 1px theme-colors('grays', 'gray-10', 0.1));
    @extend %isIn;
    @extend %flex-column;
    @include transform(translateX(100%));

    &.searchToggle {
      opacity: 1;
      @extend %isIn;
      @include transform(translateX(0));
    }
  }
}

.verticalList {
  @extend %flex-column;

  li {
    flex: 1 1 auto;
    @extend %flex-column;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

.horizontalList {
  @extend %flexbox;
  @extend %item-center;

  li {
    flex: 0 0 auto;
    @extend %flex-column;

    &:not(:last-child) {
      margin-right: 2rem;
    }

    label {
      flex: 1 1 auto;
      margin: 0;
    }
  }
}

.headerSelect {
  flex: 0 0 auto;
  min-width: 180px;
  max-width: 180px;
  @extend %flex-column;
}

.headerProjectname {
  flex: 0 0 auto;
  @extend %flex-column;

  p {
    font-size: 14px;
    color: theme-colors('grays', 'gray-6');
  }

  h4 {
    font-size: 16px;
    color: theme-colors('grays', 'gray-8');
  }
}

/* - ========= Layout Section ========= - */
.layoutBlock {
  flex: 1 1 auto;
  max-width: 100%;
  padding-top: 0.25rem;
  @extend %flexbox;
  @extend %isIn;

  .pageAside {
    flex: 1 1 auto;
    height: calc(100vh - 6.063rem);
    max-width: 0px;
    min-width: 0px;
    overflow: hidden;
    background-color: theme-colors('grays', 'gray-0');
    @extend %flex-column;
    @extend %isIn;
  }

  .pageContent {
    flex: 1 1 auto;
    min-height: calc(100vh - 90px);
    @extend %isIn;
    @extend %flex-column;
  }

  .rightSidebar {
    flex: 1 0 auto;

    @include media('>=992px') {
      min-width: 260px;
      max-width: 260px;
    }
  }

  &.filterToggle {
    @extend %isIn;

    .pageAside {
      min-width: 260px;
      max-width: 260px;
      @extend %isIn;
    }

    .pageContent {
      max-width: calc(100% - 260px);
      @extend %isIn;
    }
  }
}

/* - ========= Search Result Section ========= - */
.resultCard {
  width: 100%;
  max-width: 100%;
  min-width: 280px;
  height: 100%;
  @extend %flex-column;

  @include media('>=992px') {
    min-height: 175px;
  }

  &__header {
    flex: 0 1 auto;
    max-width: 100%;
    row-gap: 0.5rem;
    padding: 1rem;
    background-color: theme-colors('primary', 'base', 0.03);
    @extend %flex-column;

    &__titleCheckbox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      h3 {
        flex: 0 1 auto;
        font-size: 20px;
        line-height: 26px;
        font-weight: 500;
        max-width: calc(100% - 30px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: theme-colors('grays', 'gray-10');
      }
    }

    .headerBottom {
      flex: 1 1 auto;
      column-gap: 1rem;
      row-gap: 1rem;
      @extend %flexbox;
      @extend %item-center;
      @extend %item-wrap;

      &__searchcontent {
        flex: 0 0 auto;

        .searchitemList {
          row-gap: 1rem;
          column-gap: 1rem;
          @extend %flexbox;
          @extend %item-center;
          @extend %item-wrap;

          li {
            column-gap: 0.5rem;
            min-width: 60px;
            @extend %flexbox;
            @extend %item-center;

            span {
              flex: 0 0 auto;

              &:first-child {
                width: 16px;
                height: 16px;
                @extend %flexbox;
                @extend %all-center;

                svg {
                  width: 100%;

                  path {
                    fill: theme-colors('grays', 'gray-6');
                  }
                }
              }

              &:last-child {
                font-size: 14px;
                font-weight: 600;
                color: theme-colors('grays', 'gray-7');
              }
            }
          }
        }
      }

      &__controlsicon {
        flex: 0 0 auto;

        .controlsList {
          column-gap: 1rem;
          @extend %flexbox;
          @extend %item-center;

          li {
            flex: 0 0 auto;
            min-width: 80px;

            a {
              height: 26px;
              padding: 0 0.5rem;
              cursor: pointer;
              font-size: 12px;
              border: solid 1px theme-colors('grays', 'gray-10');
              text-transform: uppercase;
              color: theme-colors('grays', 'gray-10');
              column-gap: 0.5rem;
              @extend %flexbox;
              @extend %item-center;
              @extend %radius-md;

              &:hover:not(.disabled) {
                color: theme-colors('grays', 'gray-0');
                background-color: theme-colors('secondary', 'base');
                border-color: theme-colors('secondary', 'base');
                @extend %isIn;

                svg {
                  @extend %isIn;

                  path {
                    fill: theme-colors('primary', 'dark');
                    @extend %isIn;
                  }
                }

                span {
                  color: theme-colors('primary', 'dark');
                }
              }

              span {
                flex: 0 0 auto;

                &:first-child {
                  width: 16px;
                  height: 16px;
                  @extend %flexbox;
                  @extend %all-center;

                  svg {
                    width: 100%;
                    height: 100%;
                    @extend %isOut;

                    path {
                      fill: theme-colors('grays', 'gray-10');
                      @extend %isOut;
                    }
                  }
                }
              }

              &.disabled {
                cursor: not-allowed;
                border: solid 1px theme-colors('grays', 'gray-4');
                color: theme-colors('grays', 'gray-4');

                span {
                  svg {
                    path {
                      fill: theme-colors('grays', 'gray-4');
                      @extend %isOut;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &__content {
    flex: 1 1 auto;
    padding: 1rem;
    row-gap: 1rem;
    @extend %flex-column;

    .tagsBlock {
      row-gap: 0.25rem;
      @extend %flex-column;

      h3 {
        flex: 0 0 auto;
        font-size: 14px;
        line-height: 31px;
      }

      .tagList {
        column-gap: 0.5rem;
        row-gap: 0.5rem;
        @extend %flexbox;
        @extend %item-center;
        @extend %item-wrap;

        li {
          max-width: 100%;
          min-width: 40px;
          flex: 0 0 auto !important;
        }
      }
    }

    .articleText {
      @extend %flex-column;

      p {
        font-size: 14px;
        word-wrap: break-word;
        column-gap: 0.5rem;

        a {
          display: inline-block;
          margin-left: 0.5rem;
          color: theme-colors('primary', 'base');
        }
      }
    }

    .resultMetatag {
      flex: 1 1 auto;
      row-gap: 0.5rem;
      @extend %flex-column;

      .metaTagList {
        column-gap: 0.5rem;
        row-gap: 0.5rem;
        @extend %flexbox;
        @extend %item-center;
        @extend %item-wrap;

        li {
          flex: 0 0 auto !important;
          max-width: 100%;

          column-gap: 0.5rem;
          row-gap: 0.5rem;
          @extend %flexbox;
          @extend %item-center;
          @extend %item-wrap;
          @extend %radius-md;

          label {
            font-weight: 500;
            color: theme-colors('grays', 'gray-5');
          }

          .metaListTagElement {
            column-gap: 0.5rem;
            row-gap: 0.5rem;
            min-width: 40px;
            max-width: 100%;
            @extend %flexbox;
            @extend %item-center;
            @extend %item-wrap;

            span {
              flex: 0 0 auto;
              word-wrap: break-word;
              word-break: break-all;
              padding: 0.25rem 0.5rem;
              max-width: 100%;
              text-transform: capitalize;
              background-color: theme-colors('primary', 'base', 0.1);
              @extend %radius-md;
            }
          }

          a {
            color: theme-colors('secondary', 'base');
          }
        }
      }

      .metaTagMoreLink {
        flex: 0 0 auto;
        display: inline-block;
        color: theme-colors('primary', 'base');
      }
    }
  }

  &__controls {
    flex: 0 1 auto;
    padding-top: 1rem;
    @extend %flexbox;

    .quickCods {
      @extend %flex-column;

      .codeList {
        flex: 0 0 auto;
        @extend %flexbox;
        @extend %item-center;
        @extend %item-wrap;

        li {
          flex: 0 0 auto;
          min-width: 60px;
          @extend %flexbox;

          &:not(last-child) {
            margin-right: 6px;
            margin-bottom: 6px;
          }
        }
      }
    }
  }
}

/* ----- Search Result wrapper --- - */
.resultWrapper {
  flex: 1 1 auto;
  @extend %flex-column;

  .resultBlock {
    flex: 0 1 auto;
    display: grid;
    gap: 1rem 0;
    grid-template-columns: repeat(1, 100%);
    grid-template-rows: 1fr;

    &__element {
      flex: 1 1 auto;
      @extend %flex-column;
    }

    &.gridTwo {
      gap: 1rem;
      grid-template-columns: repeat(2, calc(50% - 0.5rem));

      .resultCard {
        flex: 1 1 auto;

        .headerBottom {
          row-gap: 1rem;
          @extend %flex-column;
          @extend %item-start;
        }

        .controlsList {
          flex: 0 1 auto;

          li {
            flex: 0 0 auto;
            min-width: 30px;

            span {
              flex: 0 0 auto;

              &:last-child {
                display: none;
              }
            }
          }
        }
      }
    }

    &.gridThree {
      gap: 1rem;
      grid-template-columns: repeat(3, calc(33.333% - 0.65rem));

      .resultCard {
        flex: 1 1 auto;

        .headerBottom {
          row-gap: 1rem;
          @extend %flex-column;
          @extend %item-start;

          .searchitemList {
            row-gap: 0.5rem;
            @extend %flex-column;
            @extend %item-start;
          }

          .controlsList {
            flex: 0 1 auto;

            li {
              flex: 0 0 auto;
              min-width: 30px;

              span {
                flex: 0 0 auto;

                &:last-child {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* - ========= Search Result Section END ========= - */
.tableActions {
  column-gap: 1rem;
  @extend %flexbox;
  @extend %item-center;

  li {
    flex: 0 0 auto;
    cursor: pointer;
    @extend %flexbox;
    @extend %all-center;

    span {
      flex: 1 1 auto;
      @extend %isOut;
      width: 16px;
      height: 16px;
      max-width: 16px;
      max-height: 16px;
      @extend %flexbox;
      @extend %all-center;

      svg {
        @extend %isOut;
        width: 100%;
        height: 100%;

        path {
          @extend %isOut;
          fill: theme-colors('primary', 'dark');
        }
      }

      &:hover {
        @extend %isIn;

        svg {
          @extend %isIn;

          path {
            @extend %isIn;
            fill: theme-colors('secondary', 'dark');
          }
        }
      }
    }
  }
}

.chartWrapper {
  flex: 1 1 auto;
  row-gap: 1.5rem;
  @extend %flex-column;

  [class~='simplebar-placeholder'] {
    display: none;
  }
}

.chartItem {
  flex: 1 1 auto;
  height: calc(100vh - 10px);
  background-color: theme-colors('grays', 'gray-0');
  @extend %flex-column;
}

.previewCharts {
  canvas:nth-of-type(2) {
    display: none;
  }
}

.paginationWrapper {
  @extend %flexbox;
  @extend %item-center;
  @extend %justify-between;

  .searchresultCount {
    flex: 0 0 auto;
    @extend %flexbox;
    @extend %item-center;

    span {
      margin-right: 5px;
      color: theme-colors('primary', 'dark');
    }
  }

  .searchresultPage {
    flex: 0 0 auto;
  }
}

.searchInfoToolTipInnerOl {
  @extend %flex-column;

  em {
    background-color: transparent;
  }

  p {
    color: white;
    margin: 5px 5px 5px 30px;
  }

  li {
    counter-increment: item;
    margin-bottom: 5px;

    &:before {
      margin-right: 10px;
      content: counter(item);
      // background: lightblue;
      border-radius: 100%;
      color: white;
      width: 1.2em;
      text-align: center;
      display: inline-block;
    }
  }
}

.searchInfoToolTipInnerUl {
  @extend %flex-column;

  li {
    margin-bottom: 5px;

    &:before {
      margin-bottom: 3px;
      margin-right: 10px;
      content: '';
      background: rgb(255, 255, 255);
      border-radius: 100%;
      display: inline-block;
      width: 8px;
      height: 8px;
    }
  }
}

.defaultGroupQuery, .defaultGroupQuery:hover {
  background-color: rgba(60, 50, 242, 0.1) !important;
  color: #000  !important;
}


.controlError{
  display: block;
  color: red;
  font-size: smaller;

}